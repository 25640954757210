<template>
  <a-spin :spinning="spinShow">
    <!--style="color: #495060;"-->
    <form method="post" action="#" id="printJS-form">
      <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:18.0pt">{{tempDepartment}}电话处理记录</span></b></p>
      <div align="center">
        <table class="MsoTableGrid" border="1" cellspacing="0" cellpadding="0" width="576" style="width:432.1pt;border-collapse:collapse;border:none">
          <tbody><tr style="height:9.7pt">
            <td width="248" style="width:188.85pt;border:solid windowtext 1.0pt;padding:
  0cm 5.4pt 0cm 5.4pt;height:9.7pt">
              <p class="MsoNormal" style="margin-left: 7px"><span style="font-size:12.0pt">来电号码：{{formItem.fromPhone}}</span></p>
            </td>
            <td width="188" style="width:148.85pt;border:solid windowtext 1.0pt;border-left:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:9.7pt">
              <p class="MsoNormal"><span style="font-size:12.0pt">来电人姓名：{{formItem.fromName}}</span></p>
            </td>
            <td width="178" style="width:148.85pt;border:solid windowtext 1.0pt;border-left:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:9.7pt">
              <p class="MsoNormal"><span style="font-size:12.0pt">值班人员：{{ formItem.officer}}</span></p>
            </td>
          </tr>
          <tr style="height:9.7pt">
            <td width="610" colspan="3" style="width:432.1pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:9.7pt">
              <p class="MsoNormal"><span style="font-size:12.0pt">来电地址：{{ formItem.fromAddress}}</span></p>
            </td>
          </tr>
          <tr style="height:55pt">
            <td width="576" colspan="3" valign="top" style="width:432.1pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:55pt">
              <p class="MsoNormal"><span style="font-size:12.0pt">调度中心处理意见：{{ formItem.resolution}}</span></p>
              <p class="MsoNormal"><span lang="EN-US" style="font-size:12.0pt">&nbsp;</span></p>

              <p class="MsoNormal" style="float: right"><span lang="EN-US" style="font-size:18.0pt">
  </span><span style="font-size:12.0pt;margin-right: 6px">{{formItem.dispatchTime|timeFilter}}</span></p>
            </td>
          </tr>
          <tr style="height:55pt">
            <td width="576" colspan="3" valign="top" style="width:432.1pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:55pt">
              <p class="MsoNormal"><span style="font-size:12.0pt">现场处理结果：{{ formItem.handleResult}}</span></p>
              <p class="MsoNormal"><span lang="EN-US" style="font-size:12.0pt">&nbsp;</span></p>
              <p class="MsoNormal" style="font-size:18.0pt;float: right;margin-right: 6px"><b><span lang="EN-US" > </span></b><span style="font-size:12.0pt">处理人：{{ formItem.handlePerson}}</span></p>
              <div style="clear: both"></div>
              <p class="MsoNormal" style="float: right;margin-right: 6px"><span lang="EN-US" style="font-size:12.0pt">
  </span><span style="font-size:12.0pt">{{formItem.handleCompleteTime|timeFilter}}</span></p>
            </td>
          </tr>
          <tr style="height:55pt">
            <td width="576" colspan="3" valign="top" style="width:432.1pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:55pt">
              <p class="MsoNormal"><span style="font-size:12.0pt">用户回访意见：{{ formItem.feedbackResult}}</span></p>
              <p class="MsoNormal"><b><span lang="EN-US" style="font-size:18.0pt">&nbsp;</span></b></p>
              <p class="MsoNormal" style="font-size:18.0pt;float: right;margin-right: 6px"><b><span lang="EN-US"></span></b><span style="font-size:12.0pt">回访人：{{ formItem.feedbackPerson}}</span></p>
              <div style="clear: both"></div>
              <p class="MsoNormal" style="float: right;margin-right: 6px"><b><span lang="EN-US" style="font-size:18.0pt">
  </span></b><span style="font-size:12.0pt">{{formItem.feedbackTime|timeFilter}}</span></p>
            </td>
          </tr>
          <tr style="height:55pt">
            <td width="576" colspan="3" valign="top" style="width:432.1pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:55pt">
              <p class="MsoNormal"><span style="font-size:12.0pt">领导意见：{{ formItem.confirmResult}}</span></p>
              <p class="MsoNormal"><span lang="EN-US" style="font-size:12.0pt">&nbsp;</span></p>
              <p class="MsoNormal"  style="font-size:18.0pt;float: right;margin-right: 6px"><b></b><span style="font-size:12.0pt">确认人：{{ formItem.feedbackPerson}}</span></p>
              <div style="clear: both"></div>
              <p class="MsoNormal" style="float: right;margin-right: 6px"><span lang="EN-US" style="font-size:12.0pt">
  </span><span style="font-size:12.0pt">{{formItem.confirmTime|timeFilter}}</span></p>
            </td>
          </tr>
          </tbody></table>

      </div>
    </form>
  </a-spin>
</template>

<script>
  import Vue from 'vue'
  import moment from 'moment'
  import SERVICE_URLS from '@/api/service.url'
  import { damageReportFormVO } from './common/common'
  import printJS from 'print-js'

  export default {
    name: 'DamageReportPrint',
    data() {
      return {
        spinShow: false,
        // checkBillItem的VO
        formItem: damageReportFormVO(),
        // 确认流转记录列表
        tempDepartment: ''
      }
    },
    computed: {
      //获取当前单位id
      currentOrg () {
        return this.$store.getters.currentOrg
      }
    },
    methods: {
      loadData(id) {
        this.tempDepartment = this.currentOrg.name;
        this.clearData()
        Promise.all([
          Vue.http.get(SERVICE_URLS.ticket.damageReport.view.path.replace('{id}', id)),
          /*Vue.http.get(SERVICE_URLS.dpc.exchangeRecordApi.query.path.replace('{id}', id))*/
        ]).then(res => {
          let success = true
          if (res[0].code === 0) {
            const body = res[0].body
            // ↓获取checkBillItem的数据
            Object.assign(this.formItem, body.damageReportRecord)
          } else {
            success = false
          }
          if (!success) {
            this.$message.error('详情数据初始化错误')
          }
          this.spinShow = false
        }).catch(error => {
          console.log(error)
          this.spinShow = false
          this.$message.error('详情数据初始化错误')
        })
      },
      onPrint() {
        // 样式必须内敛，否则打印无效
        printJS({
          printable: 'printJS-form',
          type: 'html',
          ignoreElements: ['printBtn'],
          scanStyles: false,
          documentTitle: '宁夏水投兴庆水务有限公司电话处理记录'
        })
      },
      clearData() {
        this.spinShow = false
        this.formItem = damageReportFormVO()
      }
    },
    filters: {
      timeFilter(time) {
        if (time) {
          return moment(time).format('YYYY年MM月DD日 HH时mm分')
        } else {
        }
      }
    }
  }
</script>

<style scoped>
  @page {
    /*打印的页面定义样式*/
    size: auto;
    margin: 0;
  }
</style>